<template>
	<div id="user-center">
		<v-head></v-head>
		<div class="user-avatar-box">
			<div class="user-avatar" v-if="userInfo">
				<div class="img-box">
					<img :src="userInfo.photo || ''" :alt="userInfo.clientname || userInfo.clientno" class="img-avatar">
				</div>
				<span class="user-name">{{userInfo.clientname || userInfo.clientno}}</span>
			</div> 
		</div>
		<div class="user-tabs-w">
			<ul class="user-tabs">
				<li class="user-tab"><router-link :class="{'user-tab-v': true, 'current': pathName.indexOf('userInfo') != -1 || pathName.indexOf('updatePassword') != -1}" to="/user/userInfo">用戶信息</router-link></li>
				<li class="user-tab"><router-link :class="{'user-tab-v': true, 'current': pathName.indexOf('booking') != -1 }" to="/user/booking">預約/記錄</router-link></li>
				<li class="user-tab"><router-link :class="{'user-tab-v': true, 'current': pathName.indexOf('card') != -1 }" to="/user/card">計劃/卡片</router-link></li>
				<li class="user-tab"><router-link :class="{'user-tab-v': true, 'current': pathName.indexOf('aq-news') != -1 }" to="/user/aq-news">問答/資訊</router-link></li>
				<li class="user-tab" v-if="isDoctorUser"><router-link :class="{'user-tab-v': true, 'current': pathName.indexOf('association') != -1 }" to="/user/association">學會管理</router-link></li>
				<li class="user-tab"><router-link :class="{'user-tab-v': true, 'current': pathName.indexOf('resume') != -1 }" to="/user/resume">我的履歷</router-link></li>
				<li class="user-tab"><router-link :class="{'user-tab-v': true, 'current': pathName.indexOf('applyJobs') != -1 }" to="/user/applyJobs">我的職位申請</router-link></li>
			</ul>
		</div>
		<router-view></router-view>
		<v-foot></v-foot>
	</div>
</template>

<script>
	import vHead from '@/layouts/Head.vue'
	import vFoot from '@/layouts/Foot.vue'
	export default {
		name: "user-center",
		components: {
			vHead,
			vFoot
		},
		mounted(){
			this.pathName = this.$route.path
			if(!this.$store.getters.token) {
				this.$router.push('/')
			}
			if(this.userInfo) {
				this.isDoctorUser = !!this.userInfo.doctorglobaluserid
			}
		},
		data() {
			return {
				pathName: '',
				userInfo: this.$store.getters.userInfo,
				isDoctorUser: false,
			}
		},
		watch: {
			$route(to,from){
				this.userInfo = this.$store.getters.userInfo
				this.pathName = to.path
				if(!this.$store.getters.token) {
					this.$router.push('/')
				} else {
					if(this.pathName.indexOf('/user/association') != -1) {
						if(!this.isDoctorUser) {
							this.$router.push('/user/home')
						}
					}
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	#user-center {
		width: 100%	;
	}
	.user-avatar-box {
		width: 100%;
		height: 210px;
		background-image: url(../../../assets/imgs/pc/user/img_banner@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.user-avatar-box > .user-avatar {
		width: 1000px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 210px;
		margin: 0px auto;
	}

	.user-avatar-box > .user-avatar .img-box {
		width: 108px;
		height: 108px;
		border-radius: 50%;
		background-color: #969696;
	}

	.user-avatar-box > .user-avatar .img-avatar {
		width: 108px;
		height: 108px;
		border-radius: 50%;
	}

	.user-avatar-box > .user-avatar .img-avatar[src=''] {
		display: none;
	}

	.user-avatar-box > .user-avatar > .user-name {
		font-weight: 20px;
		font-weight: 400;
		margin-top: 19px;
		color: #fff;
	}

	.user-tabs-w {
		width: 100%;
		height: 94px;
		background-color: #fff;
		margin-bottom: 10px;
	}

	.user-tabs {
		display: flex;
		flex-direction: row;
		width: 1000px;
		height: 94px;
		margin: 0 auto;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.user-tabs .user-tab {
		display: block;
		width: 133px;
	}

	.user-tabs .user-tab:nth-child(1) {
		margin-left: 200px;
	}

	.user-tabs .user-tab > .user-tab-v {
		display: inline-block;
		color: #969696;
		font-size: 16px;
		font-weight: 400;
		height: 15px;
		line-height: 15px;
		position: relative;
		padding-bottom: 20px;
		margin-top: 40px;
	}

	.user-tabs .user-tab > .user-tab-v.current {
		position: relative;
		font-weight: bold;
		color: #231F20;
	}

	.user-tabs .user-tab > .user-tab-v.current::after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -16px;
		width: 20px;
		height: 4px;
		background-color: #231F20;
	}
</style>
